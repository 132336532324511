import { useTranslation } from "next-i18next"

import {
  buildAbsoluteUrl,
  isMobile,
  goStartAnonymousSession,
} from "../../../utils/browser"
import Button from "../../../components/Button"
import TalkToSolace from "../../../components/TalkToSolace"
import Skeleton from "@mui/material/Skeleton"
import Style from "./HomeMainButtons.module.scss"

import useUser from "../../../hooks/useUser"

export default function HomeMainButtons() {
  const { t } = useTranslation("common")
  const { status } = useUser()

  return (
    <div className={Style.Wrapper}>
      <div className={Style.HeroVideoAndCTA}>
        <Button
          style="secondary"
          className=" mb-3 md:mb-0 md:mr-3"
          onClick={goStartAnonymousSession}
          styles={{
            border: "2px solid #000000",
          }}
        >
          {t("COMMON_START_ANONYMOUS_SESSION")}
        </Button>
        <TalkToSolace />

        {status === "loading" && (
          <>
            <Skeleton
              variant="rounded"
              style={{ borderRadius: "20px", marginBottom: "20px" }}
              className="mr-5"
              width={400}
              height={55}
            />
            <Skeleton
              variant="rounded"
              style={{ borderRadius: "20px" }}
              className="mr-5"
              width={400}
              height={55}
            />
          </>
        )}
      </div>
      <div className={Style.NoCardCreditRequired}>
        {t("COMMON_NO_PAYMENT_REQUIRED")}
      </div>
    </div>
  )
}
