import { isMobile } from "../../../utils/browser"

import { useTranslation } from "next-i18next"

import Styles from "./VideoPlayer.module.scss"

export default function VideoPlayer() {
  const { t } = useTranslation("common")

  return (
    <div
      className={Styles.VideoPlayerWrapper}
      style={{
        height: "350px",
        borderRadius: "35px",
        marginTop: isMobile() ? "320px" : "100px",
        marginBottom: isMobile() ? "50px" : "0",
        maxWidth: "600px",
      }}
    >
      <div className={Styles.VideoPlayer}>
        <iframe
          width="270"
          height="480"
          src="https://www.youtube.com/embed/NqxyMK66XFo"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={t("HOME_WATCH_SOLACE_VIDEO")}
        />
      </div>
    </div>
  )
}
