import { useTranslation } from "next-i18next"
import Image from "next/image"
import { usePlayVideo } from "../../../hooks/usePlayVideo"

import styles from "./Testimonials.module.scss"
export default function TestimonialVideoCard({
  videoID,
  name,
  occupation,
  backgroundPosition = "center",
  backgroundSize = "cover",
  image,
}) {
  const { t } = useTranslation("common")
  const { openPlayVideo } = usePlayVideo({ videoID })

  return (
    <div
      className={styles.VideoCard}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize,
        backgroundPosition,
      }}
      onClick={openPlayVideo}
    >
      <div className={styles.VideoCardHeaderWrapper}>
        <h3 className={styles.VideoCardTitle}>{name}</h3>
        <div className={styles.VideoCardSubTitle}>{occupation}</div>
      </div>

      <div className={styles.VideoCardPlayButton}>
        <Image
          src={"/assets/testimonial-play-button.svg"}
          width="54"
          height="49"
          alt={t("HOME_TESTIMONIALS_WATCH")}
        />
      </div>
    </div>
  )
}
