import { useTranslation } from "next-i18next"

import styles from "./HowWorks.module.scss"
import HowWorksCard from "./HowWorksCard"

export default function HowWorks() {
  const { t } = useTranslation("common")

  return (
    <div className={styles.Wrapper}>
      <div className={styles.HeaderWrapper}>
        <h2 className={styles.HeaderTitle} id="how-it-works">
          {t("HOME_HOW_IT_WORKS_TITLE")}
        </h2>
        <div className={styles.HeaderSubTitle}>
          {t("HOME_HOW_IT_WORKS_SUBTITLE")}
        </div>
      </div>
      <div className={styles.HowWorksCards}>
        <HowWorksCard
          imageUrl="/assets/ai-therapy-solace-part-1.webp"
          step={1}
          imageAlt={t("HOME_HOW_IT_WORKS_STEP_1_IMG_ALT")}
          title={t("HOME_HOW_IT_WORKS_STEP_1_TITLE")}
          description={t("HOME_HOW_IT_WORKS_STEP_1_DESCRIPTION")}
        />
        <HowWorksCard
          imageUrl="/assets/ai-therapy-solace-part-2.webp"
          step={2}
          imageAlt={t("HOME_HOW_IT_WORKS_STEP_2_IMG_ALT")}
          title={t("HOME_HOW_IT_WORKS_STEP_2_TITLE")}
          description={t("HOME_HOW_IT_WORKS_STEP_2_DESCRIPTION")}
        />
        <HowWorksCard
          imageUrl="/assets/ai-therapy-solace-part-3.webp"
          step={3}
          imageAlt={t("HOME_HOW_IT_WORKS_STEP_3_IMG_ALT")}
          title={t("HOME_HOW_IT_WORKS_STEP_3_TITLE")}
          description={t("HOME_HOW_IT_WORKS_STEP_3_DESCRIPTION")}
        />
      </div>
    </div>
  )
}
