import VideoPlayer from "./VideoPlayer"
import DisclaimerWarning from "../../../components/DisclaimerWarning"
import { useRef } from "react"
import { Trans, useTranslation } from "next-i18next"
import clsx from "clsx"

import HomeMainButtons from "./HomeMainButtons"

import Styles from "./Hero.module.scss"

export default function Hero({ announcement }) {
  const { t } = useTranslation("common")

  const hasAnnouncement = announcement && announcement.title

  const myRef = useRef(null)

  return (
    <div ref={myRef} id="hero">
      <div className={Styles.Announcement}>
        <div className={Styles.AnnouncementText}>
          <Trans i18nKey="HOME_ANNOUNCEMENT" />
        </div>
      </div>

      <div
        className={clsx(
          Styles.Hero,
          hasAnnouncement && Styles.HeroWithAnnouncement
        )}
        id="page-wrap"
      >
        <div className={Styles.HeroMainTextWrapper}>
          <h1 className={Styles.HeroMainText}>
            <Trans
              i18nKey="HOME_WELCOME_MESSAGE"
              components={{
                assistantName: <span style={{ color: "#0047FF" }} />,
              }}
            />
          </h1>
          <div className={Styles.HeroMainSubText}>
            <Trans
              i18nKey="HOME_WELCOME_MESSAGE_SUBTITLE"
              components={{
                assistantName: <span style={{ color: "#0047FF" }} />,
              }}
            />
          </div>
        </div>

        <div className={Styles.HeroMainCTAAndVideo}>
          <VideoPlayer />
        </div>
      </div>

      <div className={Styles.HeroMainCTA}>
        <h2 className="text-black hidden text-center lg:block mt-10 text-2xl">
          {t("HOME_INSTRUCTIONS")}
        </h2>

        <HomeMainButtons />
      </div>

      <div className={clsx(Styles.DisclaimerWrapper)}>
        <DisclaimerWarning />
      </div>
    </div>
  )
}
