import { useTranslation } from "next-i18next"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import HomeMainButtons from "./HomeMainButtons"
import styles from "./FAQs.module.scss"
import he from "he"

export default function FAQs({ posts = [] } = {}) {
  const { t } = useTranslation("common")

  if (posts.length === 0 || !Array.isArray(posts)) {
    return null
  }

  return (
    <div className={styles.Wrapper} id="FAQ">
      <div className={styles.HeaderWrapper}>
        <h2 className={styles.HeaderTitle}>{t("HOME_FAQS_TITLE")}</h2>
        <div className={styles.HeaderSubTitle}>{t("HOME_FAQS_SUBTITLE")}</div>
      </div>
      <div className={styles.FAQsAccordions}>
        {posts.map((post, index) => (
          <div className={styles.FAQsAccordion} key={index}>
            <Accordion expanded={true} className={styles.FAQsAccordionItem}>
              <AccordionSummary
                aria-controls={`panel${index + 1}a-content`}
                id={`panel${index + 1}a-header`}
              >
                <h3>{he.decode(post.title)}</h3>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{he.decode(post.description)}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>

      <div className={styles.HomeMainButtons}>
        <HomeMainButtons />
      </div>
    </div>
  )
}
