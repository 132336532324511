import { useTranslation } from "next-i18next"

import Image from "next/image"
import styles from "./Footer.module.scss"
import Link from "next/link"
import { goToHome, getBlogHref } from "../../utils/browser"
import { CountryAndLanguageSelectorWrapper } from "../CountryAndLanguageSelector"
import FollowUs from "../FollowUs"
import { useRouter } from "next/router"

export default function Footer({ mode = "mind-r" }) {
  const { t } = useTranslation("common", "voxme")
  const { locale } = useRouter()

  const language = locale.substring(0, 2)

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Card}>
        <div className={styles.Row}>
          <div className={styles.Logo}>
            <Image
              src="/assets/logo-white.png"
              onClick={goToHome}
              width="200"
              height="150"
              style={{
                width: "150px",
                cursor: "pointer",
              }}
              alt={t("COMMON_COMPANY_LOGO")}
            />
            <span>{t("FOOTER_LOGO_SLOGAN")}</span>

            <div className={styles.CompanyLegal}>
              <span>Mind R Artificial Intelligence Limited</span>
              <div>
                Inniscarra, Main Street, Rathcoole,
                <br />
                D24 EO29
                <br />
                Dublin <br /> Ireland
              </div>
            </div>
          </div>
          <div className={styles.Menu}>
            <span>{t("FOOTER_COMPANY")}</span>

            <div>
              <Link href="about-us">{t("FOOTER_ABOUT_US")}</Link>
            </div>

            <div>
              <Link href="/explore/resource/careers/">
                {t("FOOTER_CAREERS")}
              </Link>
            </div>

            <div>
              <Link href="privacy-policy">{t("FOOTER_PRIVACY_POLICY")}</Link>
            </div>

            <div>
              <Link href="terms-and-conditions">
                {t("FOOTER_TERMS_OF_USE")}
              </Link>
            </div>

            {mode === "mind-r" && (
              <div>
                <Link href={getBlogHref(language)}>
                  {t("FOOTER_MENTAL_HEALTH_INSIGHTS")}
                </Link>
              </div>
            )}

            <div>
              {mode === "mind-r" && (
                <Link href="sign-in">{t("FOOTER_THERAPY_LOGIN")}</Link>
              )}
              {mode === "voxme" && (
                <Link href="sign-in-voxme">
                  {t("VOXME_FOOTER_LOGIN", { ns: "voxme" })}
                </Link>
              )}
            </div>

            <div>
              <Link href={`sign-up${mode === "voxme" ? "-voxme" : ""}`}>
                {t("FOOTER_SIGN_UP")}
              </Link>
            </div>
          </div>
          <div className={styles.Social}>
            <FollowUs />

            <div className={styles.CompanyAddress}>
              <span>{t("FOOTER_CONTACT_US")}</span>
              <div>
                <a href="mailto:hello@mind-r.ai">hello@mind-r.ai</a>
              </div>
              <div>
                <a href="https://api.whatsapp.com/message/PDZSHSCRI3A3B1?autoload=1&app_absent=0">
                  +353 1 442 8352
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.Language}>
              <CountryAndLanguageSelectorWrapper />
            </div>

            {mode === "mind-r" && (
              <div className={styles.Menu}>
                <span>{t("FOOTER_TESTS")}</span>

                <div>
                  <Link href="/free-depression-test">
                    {t("FOOTER_FREE_DEPRESSION_TEST")}
                  </Link>
                </div>

                <div>
                  <Link href="/online-adhd-testing">
                    {t("FOOTER_FREE_ADHD_TEST")}
                  </Link>
                </div>

                <div>
                  <Link href="/stress-test">
                    {t("FOOTER_FREE_STRESS_TEST")}
                  </Link>
                </div>
                <div>
                  <Link href="/anxiety-test">
                    {t("FOOTER_FREE_ANXIETY_TEST")}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.Legal}>
          <span>© 2023 Mind-r.ai</span>
          <span>{t("FOOTER_ALL_RIGHTS_RESERVED")}</span>
        </div>
      </div>
    </div>
  )
}
