import { useTranslation } from "next-i18next"
import Image from "next/image"

import styles from "./Blog.module.scss"

const formatDate = (dateString) => {
  const date = new Date(dateString)

  // You can customize the options to suit your needs
  const dateOptions = { year: "numeric", month: "long", day: "numeric" }

  return `${date.toLocaleDateString(undefined, dateOptions)}`
}

const ORDER_COLORS = [
  "rgba(172, 255, 205, 0.2)",
  "rgba(247, 160, 252, 0.2)",
  "rgba(159, 193, 255, 0.2)",
]

export default function BlogPostCard({
  title,
  order,
  description,
  author,
  date,
  link,
  postImageUrl,
}) {
  const { t } = useTranslation("common")
  return (
    <div
      className={styles.BlogCard}
      style={{
        backgroundColor: ORDER_COLORS[order],
      }}
      onClick={() => {
        window.location.href = link
      }}
    >
      <div className={styles.BlogCardImage}>
        <Image src={postImageUrl} width="355" height="244" alt={""} />
      </div>

      <div className={styles.BlogCardBlog}>
        <h3 className={styles.BlogCardTitle}>{title}</h3>
        <div
          className={styles.BlogCardDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={styles.BlogCardAuthor}>
        <span>
          {t("HOME_BLOG_POSTED_BY")}: {author}
        </span>{" "}
        {formatDate(date)}
      </div>
    </div>
  )
}
