import { useTranslation } from "next-i18next"

import Link from "next/link"

export default function DisclaimerWarning({ className = "" }) {
  const { t } = useTranslation("common")

  return (
    <div className={`${className} text-sm font-sans text-black `}>
      <div className="text-left">
        <h3 className="font-sans font-bold mr-2 clear-none inline-block">
          {t("COMMON_DISCLAIMER")}
        </h3>
        <span>{t("COMMON_DISCLAIMER_TEXT")}</span>
        <Link
          href="/privacy-policy"
          className="ml-2 font-sans font-bold  "
          style={{ color: "rgb(0, 25, 255)" }}
        >
          {t("COMMON_PRIVACY_POLICY")}
        </Link>
      </div>
    </div>
  )
}
