import { useTranslation } from "next-i18next"

import { goStartSession } from "../utils/browser"
import Button from "./Button"

function TalkToSolace() {
  const { t } = useTranslation("common")
  return (
    <Button style="primary" className="" onClick={goStartSession}>
      {t("COMMON_TALK_TO_SOLACE_NOW")}
    </Button>
  )
}

export default TalkToSolace
