import { useTranslation } from "next-i18next"
import HomeMainButtons from "./HomeMainButtons"
import styles from "./CTA.module.scss"

export default function CTA() {
  const { t } = useTranslation("common")
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Card}>
        <h2 className={styles.Title}>{t("HOME_INSTRUCTIONS")}</h2>
        <HomeMainButtons />
      </div>
    </div>
  )
}
