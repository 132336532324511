import { useMemo } from "react"
import clsx from "clsx"
import Styles from "./Button.module.scss"

export default function Button({
  style = "primary",
  onClick,
  className = "",
  children,
  styles = {},
  ...props
}) {
  const backgroundColor = useMemo(() => {
    if (style === "primary") return "#172036"
    if (style === "danger") return "#CA4D4D"
    return "white"
  }, [style])

  const borderColor = useMemo(() => {
    if (style === "danger") return "#CA4D4D"
    if (style === "primary") {
      return "#172036"
    }
    return "#A8B9BF"
  }, [style])

  return (
    <button
      onClick={onClick}
      className={clsx(
        `${
          ["primary", "danger"].includes(style) ? "text-white" : "text-black"
        }  p-2 px-5 font-sans tracking-wider  border-solid border-2  ${className}`,
        Styles.Button
      )}
      style={{
        backgroundColor,
        borderRadius: "20px",
        borderColor,
        height: "56px",
        fontSize: "16px",
        ...styles,
      }}
      {...props}
    >
      {children}
    </button>
  )
}
