import Image from "next/image"

import styles from "./HowWorks.module.scss"
export default function HowWorksCard({
  imageUrl = "",
  imageAlt = "",
  step,
  title,
  description,
}) {
  const stepsColor = {
    1: "#5dad7c",
    2: "#9a6be6",
    3: "#7c82ff",
  }
  return (
    <div className={styles.HowWorksCard}>
      <div className={styles.HowWorksCardHeaderWrapper}>
        <div className={styles.HowWorksCardImage}>
          <Image src={imageUrl} width={400} height={400} alt={imageAlt} />
        </div>

        <div className={styles.HowWorksCardInfo}>
          <div>
            <div
              style={{ backgroundColor: stepsColor[step] }}
              className={styles.HowWorksCardStep}
            >
              {step}
            </div>
          </div>
          <div>
            <h3 className={styles.HowWorksCardTitle}>{title}</h3>
            <div className={styles.HowWorksCardDescription}>{description}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
