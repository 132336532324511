import Image from "next/image"

import styles from "./Testimonials.module.scss"
export default function TestimonialPersonCard({
  name,
  occupation,
  avatarUrl,
  testimonial,
}) {
  return (
    <div className={styles.PersonCard}>
      <div className={styles.PersonCardHeaderWrapper}>
        <div className={styles.PersonCardAvatar}>
          <Image src={avatarUrl} width="75" height="75" alt={""} />
        </div>

        <div className={styles.PersonCardPerson}>
          <h4 className={styles.PersonCardPersonName}>{name}</h4>
          <div className={styles.PersonCardPersonOccupation}>{occupation}</div>
        </div>
      </div>
      <div className={styles.PersonCardTestimonial}>{testimonial}</div>
    </div>
  )
}
