import { useModal } from "./useModal"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import HomeMainButtons from "../components/local/Home/HomeMainButtons"

// Map of video IDs
const VIDEO_HERO_IDS = {
  de: "U6437-bIxC8",
  pt: "KNpiNJ7j5-U",
  en: "NqxyMK66XFo",
  "pt-PT": "4C7j6Um4Te4",
  default: "NqxyMK66XFo",
}

const PlayContent = ({ videoID }) => {
  const { t } = useTranslation("common")

  const selectedVideo = `https://www.youtube.com/embed/${videoID}?autoplay=1`

  return (
    <div
      className="flex justify-center items-center"
      style={{
        height: "calc(100vh - 100px)",
        flexDirection: "column",
      }}
    >
      <iframe
        width="480"
        height="853"
        src={selectedVideo}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={t("HOME_WATCH_SOLACE_VIDEO")}
      />

      <HomeMainButtons />
    </div>
  )
}

export function usePlayHeroVideo() {
  const { openModal } = useModal()
  const { locale } = useRouter()
  const [language] = locale.split("-")

  // Construct full video URL with video ID
  const videoID =
    VIDEO_HERO_IDS[locale] || VIDEO_HERO_IDS[language] || VIDEO_HERO_IDS.default

  const openPlayVideo = () => {
    openModal(<PlayContent videoID={videoID} />, {
      closeable: true,
      fullScreen: true,
      fullWidth: true,
      maxWidth: false,
      style: {
        width: "100vw",
      },
    })
  }

  return { openPlayVideo }
}

export function usePlayVideo({ videoID }) {
  const { openModal } = useModal()
  const { locale } = useRouter()
  const [language] = locale.split("-")
  const openPlayVideo = () => {
    openModal(<PlayContent videoID={videoID[language]} />, {
      closeable: true,
      fullScreen: true,
      fullWidth: true,
      maxWidth: false,
      style: {
        width: "100vw",
      },
    })
  }

  return { openPlayVideo }
}
