import { useTranslation } from "next-i18next"

import styles from "./Blog.module.scss"

import BlogPostCard from "./BlogPostCard"

export default function Blog({ posts }) {
  const { t } = useTranslation("common")

  console.log({ posts })
  return (
    posts.length > 0 && (
      <div className={styles.Wrapper}>
        <div className={styles.HeaderWrapper}>
          <h2 className={styles.HeaderTitle}>{t("HOME_BLOG_CTA")}</h2>
          <div className={styles.HeaderSubTitle}>{t("HOME_BLOG_SUBTITLE")}</div>
        </div>

        <div className={styles.BlogCards}>
          {posts.map((post, i) => (
            <BlogPostCard
              order={i}
              key={post.id}
              title={post.title}
              description={post.description}
              author={post.author}
              date={post.article_modified_time}
              link={post.url}
              postImageUrl={post.image.url}
            />
          ))}
        </div>
      </div>
    )
  )
}
