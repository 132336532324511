import { useTranslation } from "next-i18next"

import styles from "./Testimonials.module.scss"
import TestimonialCard from "./TestimonialVideoCard"
import TestimonialPersonCard from "./TestimonialPersonCard"

export default function Testimonials() {
  const { t } = useTranslation("common")

  const TESTIMONIALS = {
    lola: {
      es: "OV5YivhFKuI",
      fr: "7PZmSMAz3Ys",
      it: "N-Nt_hl2BmE",
      de: "Y7mXi4zIK5Q",
      pt: "sknoo_O9CuU",
      en: "fxLLpgn5C9w",
    },
    ricardo: {
      en: "A4risDEaqDQ",
      de: "EH_LzOLsPJ8",
      es: "kzpPJEkSaCs",
      fr: "PgMjSPIpuPI",
      it: "tKBVvtLHK6Q",
      pt: "1jlkPgfu_SY",
    },
    bahar: {
      en: "ZHeYhWL5hDM",
      de: "jiUYLjgaJa4",
      es: "o7CeJ7nwngg",
      fr: "5wH3Ax-PBnw",
      it: "InIGHI1CJxQ",
      pt: "KStksQeXEBc",
    },
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.HeaderWrapper}>
        <h2 className={styles.HeaderTitle}>
          {t("HOME_TESTIMONIALS_DESCRIPTION")}
        </h2>
        <div className={styles.HeaderSubTitle}>
          {t("HOME_TESTIMONIALS_CTA")}
        </div>
      </div>
      <div className={styles.VideoCards}>
        <TestimonialCard
          videoID={TESTIMONIALS.bahar}
          name={t("HOME_TESTIMONIALS_NAME_BAHAR")}
          occupation={t("HOME_TESTIMONIALS_OCCUPATION_BAHAR")}
          image="/assets/bahar.webp"
          backgroundPosition="left"
        />
        <TestimonialCard
          videoID={TESTIMONIALS.lola}
          name={t("HOME_TESTIMONIALS_NAME_LOLA")}
          occupation={t("HOME_TESTIMONIALS_OCCUPATION_LOLA")}
          image="/assets/4D81ED17-6CCD-4469-9053-1013E25A4B50.webp"
          backgroundPosition="right"
        />
        <TestimonialCard
          videoID={TESTIMONIALS.ricardo}
          name={t("HOME_TESTIMONIALS_NAME_RICARDO")}
          occupation={t("HOME_TESTIMONIALS_OCCUPATION_RICARDO")}
          image="/assets/2063EA2E-DDDE-4D52-81EB-059B1CDB435C.webp"
          backgroundPosition="0px -63px"
          backgroundSize="168%"
        />
      </div>

      <h3 className={styles.HeaderLevel3}>
        {t("HOME_TESTIMONIALS_OTHER_STORIES")}
      </h3>

      <div className={styles.PersonCards}>
        <TestimonialPersonCard
          avatarUrl="/assets/michelle.webp"
          name={t("HOME_TESTIMONIALS_NAME_MICHELLE")}
          occupation={t("HOME_TESTIMONIALS_OCCUPATION_MICHELLE")}
          testimonial={t("HOME_TESTIMONIALS_TESTIMONIAL_MICHELLE")}
        />

        <TestimonialPersonCard
          avatarUrl="/assets/regolao.webp"
          name={t("HOME_TESTIMONIALS_NAME_RICK")}
          occupation={t("HOME_TESTIMONIALS_OCCUPATION_RICK")}
          testimonial={t("HOME_TESTIMONIALS_TESTIMONIAL_RICK")}
        />

        <TestimonialPersonCard
          avatarUrl="/assets/rafa.webp"
          name={t("HOME_TESTIMONIALS_NAME_RAFA")}
          occupation={t("HOME_TESTIMONIALS_OCCUPATION_RAFA")}
          testimonial={t("HOME_TESTIMONIALS_TESTIMONIAL_RAFA")}
        />
      </div>
    </div>
  )
}
